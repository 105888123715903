<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="6" sm="12" offset-md="3">
        <v-sheet min-height="40vh" rounded="lg" class="pa-10 text-center mt-5">
          <v-alert
            v-if="$store.getters['Auth/alert'].status"
            outlined
            dense
            border="left"
            :type="
              $store.getters['Auth/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
            class="mb-10"
          >
            {{ $store.getters["Auth/alert"].message }}
          </v-alert>

          <v-icon
            :color="
              $store.getters['Auth/verified'] === 'success'
                ? 'secondary'
                : 'warning'
            "
            class="text-h1"
          >
            {{
              $store.getters["Auth/verified"] === "success"
                ? "mdi-check-circle"
                : "mdi-alert-circle"
            }}
          </v-icon>
          <p class="subtitle-1 my-6">
            {{
              $store.getters["Auth/verified"] === "success"
                ? "Account activated Successfully"
                : $store.getters["Auth/verified"] === "pending"
                ? "Activating you account"
                : "Failed to activate account. Please contact administrator"
            }}
          </p>
          <v-form>
            <v-btn
              v-if="$store.getters['Auth/verified'] === 'success'"
              color="secondary"
              tile
              large
              :to="{ name: 'login' }"
            >
              LOGIN
              <v-icon right> mdi-arrow-right </v-icon>
            </v-btn>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Verify",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Auth/verify", {
        activation_code: v.$route.params.code,
      });
    });
  },
};
</script>

<style scoped></style>
