import routes from "./ProfileRoutes";
import store from "./ProfileStore";
import links from "./ProfileLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...routes);
    }
    if (options.store) {
      options.store.registerModule("Profile", store);
      options.store.commit("Dashboard/SET_LINKS", links);
    }
  },
};
